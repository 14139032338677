@font-face {
  font-family: OfficinaBold;
  src: url(../fonts/ITC-Officina-Sans-Bold.otf);
}
@font-face {
  font-family: OfficinaRegular;
  src: url(../fonts/ITC-Officina-Sans-Regular.ttf);
}
@font-face {
  font-family: GothicRegular;
  src: url(../fonts/Trade-Gothic-LT-STD-Regular.otf);
}
@font-face {
  font-family: GothicBoldCondensed;
  src: url(../fonts/Trade-Gothic-LT-STD-Bold-Condensed.otf);
}

