@import "../../node_modules/bootstrap/scss/bootstrap";

// BREAKPOINTS
@mixin breakpoint($breakpoint) {
  @if $breakpoint== "bigDesktop" {
    @media only screen and (min-width: 1921px) {
      @content;
    }
  }
  @if $breakpoint== "laptop" {
    @media only screen and (max-width: 1920px) {
      @content;
    }
  }
  @if $breakpoint== "tablet" {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
  @if $breakpoint== "smaller-tablet" {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }
  @if $breakpoint== "font" {
    @media only screen and (max-width: 800px) {
      @content;
    }
  }
  @if $breakpoint== "mobile" {
    @media only screen and (max-width: 537px) {
      @content;
    }
  }
}

// RESET
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "DosisRegular";
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  display: flex;
  flex-direction: column;
  color: white;
  overflow-x: hidden;
  overflow-y: hidden;
  line-height: 1;
  height: 100vh;

  @include breakpoint(tablet) {
    overflow-y: auto;
  }
}
* {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: unset;
  text-decoration: none;
  &:visited {
    color: unset;
    text-decoration: none;
  }
}

input,
textarea {
  outline: none;
  color: unset;
}

i,
span {
  user-select: none; /* Non-prefixed version, currently
 -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 20px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(#444444, 0.7);
  border-radius: 20px;

  &:hover {
    background-color: rgba(#444444, 0.9);
  }
}
