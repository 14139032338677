#header {
  box-shadow: 0px 3px 6px #00000029;

  .navbar.navbar-expand-lg {
    @include breakpoint(tablet) {
      display: none;
    }
  }

  .navbar {
    display: flex;
    align-items: flex-end;
    background-color: $red;
    color: #fff;
    padding: 0;

    .header-logo {
      width: 360px;
      height: 120px;
      position: absolute;
      top: 0;
      left: 45px;
      box-shadow: 0px 3px 6px #00000029;
      z-index: 1000;

      @include breakpoint(tablet) {
        width: 315px;
        height: 105px;
        left: 20px;
      }

      @include breakpoint(mobile) {
        width: 242px;
        height: 80px;
      }

      img {
        width: 100%;
      }
    }

    .collapse.navbar-collapse {
      justify-content: flex-end;
      margin-right: 60px;

      @include breakpoint(laptop) {
        margin-right: 36px;
      }
    }

    .nav-link {
      display: flex;
      align-items: flex-end;
      color: #fff;
      font-size: 18px;
      letter-spacing: 0.72px;
      height: 74px;
      padding: 0 30px 18px;
      transition: all 0.4s;
      font-family: "GothicRegular";

      @include breakpoint(laptop) {
        padding: 0 20px 18px;
      }

      &.active {
        background-color: $red-darker;
      }

      &:hover {
        background-color: $red-darker;
      }
    }
  }

  .navbar.fixed-top {
    background-color: $red;
    box-shadow: 0px 3px 9px #3b1c5024;
    display: none;
    position: fixed;
    width: 100vw;
    z-index: 99999;

    @include breakpoint(tablet) {
      display: flex;
    }

    .container-fluid {
      display: flex;
      flex-wrap: nowrap;

      @include breakpoint(tablet) {
        height: 100%;
      }
    }

    .selected-item {
      background-color: $red-darker;
      padding: 22px 32px;
      font-size: 18px;
      font-family: "GothicRegular";
      letter-spacing: 0.72px;
      width: 100%;
      height: 97px;
      display: none;
      align-items: flex-end;

      @include breakpoint(tablet) {
        display: flex;
      }
    }

    .navbar-toggler {
      border: none;
      margin-left: auto;

      &:focus {
        box-shadow: none;
      }

      img {
        width: 20px;
        margin: 18px;

        @include breakpoint(mobile) {
          width: 16px;
          margin: 18px 12px;
        }
      }
    }

    .offcanvas.offcanvas-end {
      background-color: $red;

      @include breakpoint(mobile) {
        width: 62%;
      }
    }

    .offcanvas-header {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 0px;

      button {
        border: none;
        background-color: inherit;

        img {
          width: 20px;
          margin: 20px 6px;
          transform: rotate(-90deg);
        }
      }
    }

    .offcanvas-body {
      padding: 0px;
    }

    .navbar-nav {
      display: flex;
      flex-direction: column;

      .nav-link {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 86.4px;
        font-family: "GothicRegular";
        letter-spacing: 0.72px;
        color: #fff;
        margin-top: 8px;
        padding: 10px 40px;
        transition: all 0.2s;

        @include breakpoint(mobile) {
          font-size: 16px;
        }

        &:first-child {
          margin-top: 10px;
        }

        &:hover,
        &.active {
          opacity: 0.8;
        }
      }
    }
  }
}
