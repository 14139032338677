$red: #a61c20;
$red-darker: #820f1d;
$green: #288153;
$green-light: #236f75;
$blue-light: #499DA4;
$yellow: #f9a51a;
$orange: #df5925;
$purple: #895d9c;
$purple-light: #aa8ec1;
$gray: #868281;
$gray-light: #a9a8aa;
$white: #fefcfd;

$bg-white: #f4eeed;

$table-gray: #aba5a5;

$table-line-1: #ccc2c1;
$table-line-2: #d4ccca;
$table-line-3: #e3d9d8;
$table-line-4: #eee4e2;
$table-line-5: #f7f3f2;

$box-shadow: 0px 6px 6px #00000029;
$box-shadow-btn: 0px 3px 6px #00000029;
$box-shadow-btn-hover: 3px 3px 7px rgba(0, 0, 0, 0.4);
$btn-bg: #326891;

$tablet-margin-top: 160px;

$bottom-offset: 142px;