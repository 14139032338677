@-webkit-keyframes rotate-in {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes rotate-in {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@-webkit-keyframes rotate-out {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes rotate-out {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
.page-component {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - $bottom-offset);

  @include breakpoint(tablet) {
    height: auto;
  }
}

.cropped-mask {
  -webkit-mask: linear-gradient(-135deg, transparent 0 24px, #fff 0) top right;
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
}

.floating-action-btn {
  display: none;
  position: fixed;
  width: 72px;
  height: 62px;
  right: 21px;
  bottom: 154px;
  z-index: 1000;

  @include breakpoint(tablet) {
    display: block;
  }

  @include breakpoint(font) {
    width: 50px;
    height: 48px;
    bottom: 152px;
    right: 27px;
  }

  &__button {
    position: fixed;
    right: 30px;
    bottom: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $gray-light;
    width: 62px;
    height: 62px;
    z-index: 500;

    @include breakpoint(font) {
      width: 44px;
      height: 44px;
    }

    &.rotate-in {
      -webkit-animation: rotate-in 0.6s ease-in-out both;
      animation: rotate-in 0.6s ease-in-out both;
    }

    &.rotate-out {
      -webkit-animation: rotate-out 0.6s ease-in-out both;
      animation: rotate-out 0.6s ease-in-out both;
    }
  }

  &__whatsapp,
  &__instagram,
  &__facebook {
    position: fixed;
    width: 50px;
    height: 50px;
    right: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    @include breakpoint(tablet) {
      width: 46px;
      height: 46px;
      right: 38px;
    }

    @include breakpoint(font) {
      width: 38px;
      height: 38px;
      right: 34px;
    }

    img {
      @include breakpoint(font) {
        width: 60%;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.text-style {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.36px;
  color: $gray;
  font-family: "GothicRegular";

  @include breakpoint(font) {
    line-height: 28px;
  }

  b {
    font-family: "GothicRegular";
    font-weight: bold;
  }
}

.scroll-component {
  height: 100%;
  overflow-y: auto;

  @include breakpoint(tablet) {
    height: auto;
    overflow-y: initial;
  }
}

.scroll-component::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 20px;
}

.scroll-component::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-component::-webkit-scrollbar-thumb {
  background-color: rgba(#444444, 0.7);
  border-radius: 20px;

  &:hover {
    background-color: rgba(#444444, 0.9);
  }
}
