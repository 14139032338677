@import "./common.scss";
@import "./variables.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./components.scss";
@import "./fonts.scss";

.home-page {
  @extend .page-component;

  @include breakpoint(tablet) {
    overflow-y: hidden;
  }

  &__main {
    @extend .main-component;
    justify-content: flex-end;
    align-items: flex-start;

    #video {
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: -999;

      @include breakpoint(tablet) {
        left: -120px;
        top: 60px;
      }

      @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
      }

      @media (max-aspect-ratio: 16/9) {
        width: auto;
        height: 100%;
      }
    }

    .accordion-wrapper {
      padding: 100px 60px;
      filter: drop-shadow($box-shadow);

      @include breakpoint(tablet) {
        padding: 100px 30px;
        margin-top: 60px;
      }
    }

    .accordion {
      width: 470px;
      @extend .cropped-mask;

      @include breakpoint(tablet) {
        width: 380px;
      }

      .accordion-item {
        height: 100%;
        background-color: $yellow;
        box-shadow: $box-shadow;
      }

      .accordion-header {
        .accordion-button {
          background-color: $yellow;
          box-shadow: none;
          padding: 0px 32px;
          
          @include breakpoint(tablet) {
            padding: 0px 18px;
          }

          p {
            color: $white;
            font-size: 26px;
            line-height: 86px;
            letter-spacing: 0px;
            font-family: "OfficinaRegular";
            white-space: nowrap;

            @include breakpoint(tablet) {
              font-size: 24px;
            }
          }

          &::after {
            filter: brightness(50);
            background-size: 32px;
            width: 40px;
            height: 30px;
          }
        }
      }

      .accordion-body {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $yellow;
        color: $white;
        font-size: 18px;
        letter-spacing: 0.36px;
        line-height: 28px;
        font-family: "GothicRegular";
        padding: 10px 40px 32px 32px;

        @include breakpoint(tablet) {
          line-height: 26px;
        }
      }
    }
  }
}
