#footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background-color: $gray;
  margin-top: auto;
  min-height: 68px;
  transition: all 0.6s;
  z-index: 999;
  
  @include breakpoint(tablet) {
    min-height: 56px;
    position: fixed;
    bottom: -82px;
    display: block;
  }
  
  @include breakpoint(font) {
    min-height: 46px;
    bottom: -72px;
  }
  
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0px 80px;
    
    @include breakpoint(tablet) {
      padding: 15px 30px;
      height: fit-content;
    }
    
    @include breakpoint(font) {
      padding: 10px 30px;
    }
    
    &__mobile {
      display: none;
      background-color: $gray-light;

      @include breakpoint(tablet) {
        display: flex;
      }
    }

    .text {
      color: $white;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.36px;
      font-family: "GothicRegular";

      @include breakpoint(tablet) {
        display: none;
      }

      &__mobile {
        display: none;

        @include breakpoint(tablet) {
          display: block;
          line-height: 26px;
        }

        @include breakpoint(font) {
          font-size: 16px;
          letter-spacing: 0.32px;
        }
      }

      b {
        font-family: "GothicRegular";
        font-weight: bold;
      }
    }

    .icons {
      display: flex;
      align-items: center;

      @include breakpoint(tablet) {
        display: none;
      }

      a {
        width: 46px;
        height: 46px;
        margin: 0 6px;
        transition: all 0.2s;

        &:hover {
          cursor: pointer;
          transform: scale(1.04);
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
